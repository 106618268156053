import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Modal from "./modal"
import Kveldstur from "../img/kveldstur.jpg"

function Guiding() {
  const [show, setShow] = useState(false)
  const [title, setTitle] = useState("")
  return (
    <Layout>
      <Modal show={show} onClose={() => setShow(false)} title={title} />
      <div>
        <img
          className="h-80 w-full object-cover object-center rounded-lg shadow-lg mt-6"
          src={Kveldstur}
          alt="Kveldstur/Topptur med guide på Håheimsfjellet"
        />
      </div>
      <h1 className="text-3xl text-yellow-700 text-center my-12 font-bold ">
        Guiding
      </h1>
      <div className="grid grid-cols-1  md:grid-cols-3 gap-3 lg:gap-6 xl:gap-10 mt-6 mb-12">
        {/* Topptur */}
        <div className="shadow border p-2 flex flex-col justify-between">
          <div>
            <h2 className="text-xl font-semibold text-blue-800 text-center">
              Topptur med guiding
            </h2>
            <p>
              Vi tilbyr topptur med guiding. Vi kan vise deg dei beste fjella i
              Jølster. Vi kan og tilpasse toppturane etter kor de vil gå og
              etter ferdigheitene til deltakarane.</p>

              <p className="mt-1.5">
                  Det er ingenting som er så fasinerande som å kome seg ut på topptur om kvelden og gå topptur med hovudlykt. Dette er noko som må testast av alle!
                  Vi tilbyr derfor og kveldstur med hovudlykt.
              </p>
              
              
              <p className="mt-1.5">Prisar kr. 700,- per person. Minstepris kr. 1500,-. 
            </p>
          </div>
          <button class="mb-3 block mx-auto text-green-50 px-3 py-1 rounded-md bg-blue-600 hover:bg-blue-800 w-full mt-6"
             onClick={() => {
              setTitle("bestilling av guidet topptur")
              setShow(true)
            }}
          >
            Bestille guida topptur
          </button>
        </div>

        {/* Stisykling */}

        <div className="shadow border p-2 flex flex-col justify-between">
          <div>
            <h2 className="text-xl font-semibold text-red-800 text-center">
              Stisykling med guide
            </h2>
            <p>
              Vi tilbyr guidede turer med stisykling. Vi brenner for stisykling
              på Jølster og veit om dei beste stiane i området. Vi jobbar i tillegg med å bygge sykkelstiar på Håheim nord for Skei. Vi tek dokker gjerne med for å vise desse stiane.</p>
              
              <p className="mt-1.5">Prisar frå kr. 400,-. Minstepris. kr. 1000,-.
              Vi har og moglegheit for <Link className="text-green-800 hover:text-green-600 underline" to="/stisykling/">utleige av stisykkel eller terrengsykkel</Link>. 
            </p>
          </div>
          <button class="mb-3 block mx-auto text-green-50 px-3 py-1 rounded-md bg-red-600 hover:bg-red-800 w-full mt-6"
            onClick={() => {
              setTitle("bestilling av stisykling med guide")
              setShow(true)
            }}    
          >
            Bestille stisykling med guide
          </button>
        </div>

        {/* Fottur om sommaren */}
        <div className="shadow border p-2 flex flex-col justify-between">
          <div>
            <h2 className="text-xl font-semibold text-green-800 text-center">
              Fottur i fjellet med guide
            </h2>
            <p>
              Vi tilbyr guidede fotturer i fjella i Jølster. Om du er usikker på
              kor det er fint å gå på tur i Jølster ta kontakt og bli med oss på
              tur.{" "}
            </p>
            <p className="mt-1.5">
              Døme på turar som du kan velje mellom er Lundeskaret, Oldeskaret
              og Haugabreen, Bolsetnipa med meir. Vi tilpassar turane etter det
              de ønskjer. Prisar frå kr. 400,- Minstepris kr. 1000,- 
            </p>
          </div>
          <button class="mb-3 block mx-auto text-green-50 px-3 py-1 rounded-md bg-green-600 hover:bg-green-800 w-full mt-6"
            onClick={() => {
              setTitle("bestilling av fottur med guide")
              setShow(true)
            }}    
          >
            Ta kontakt for fotturar med guide
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default Guiding
